

import { format, parseISO } from "date-fns";
import { Vue, Component } from "vue-property-decorator";

import { TaskService } from "@/services/task";
import {
  CustomerTaskDTO,
  CustomerDTO,
  TaskDTO,
  TaskEmployeeDTO
} from "@/services/task/task.dto";

interface TableHeader {
  text: string;
  value: string;
}

@Component
export default class Customer extends Vue {
  loading = false;
  customer!: CustomerDTO;
  tasks: TaskDTO[] = [];
  employees: TaskEmployeeDTO[] = [];
  photoLinkSrc = "";
  showMeterPhoto = false;
  customerName = "";
  selectedTab = 0;
  headersForTasksTable: TableHeader[] = [
    {
      text: "Meter Number",
      value: "deviceSerialNumber"
    },
    {
      text: "Meter Reading",
      value: "meterReading"
    },
    {
      text: "Date Of Reading",
      value: "dateOfPhotoTaken"
    },
    {
      text: "Door Locked",
      value: "wasDoorLocked"
    },
    {
      text: "Reading Taken By",
      value: "employeeName"
    },
    {
      text: "Reader Note",
      value: "readerNote"
    },
    {
      text: "",
      value: "action"
    }
  ];

  data() {
    return {
      customer: {}
    };
  }

  created() {
    this.$store.commit("liveData/setSelectedActivity", 3);
    this.$store.commit("liveData/setSelectedActivityName", "Customers");
    if (!this.$route.params.id) {
      this.$router.replace("/groups");
    } else {
      this.getReadings();
    }
  }

  async getReadings() {
    try {
      const customerTaskDTO: CustomerTaskDTO = await TaskService.getTasksOfACustomer(
        this.$route.params.id
      );
      this.tasks = customerTaskDTO.tasks;
      this.customer = customerTaskDTO.customer;
      this.employees = customerTaskDTO.employees;
    } catch (err) {
      console.log(err);
    }
  }

  getEmployeeName(employeeId: string) {
    const employee: TaskEmployeeDTO | undefined = this.employees.find(
      (employee: TaskEmployeeDTO) => employee.employeeId === employeeId
    );
    return employee ? employee.name : "";
  }

  openMeterPhotoDialog(task: TaskDTO) {
    if (task.photoLink) {
      let photoLink: string = task.photoLink
        .substring(task.photoLink.indexOf("file/d/"))
        .replace("file/d/", "");
      photoLink = photoLink.substring(0, photoLink.indexOf("/view"));

      this.photoLinkSrc = `https://drive.google.com/uc?id=${photoLink}&export=download`;
      console.log(this.photoLinkSrc);

      this.showMeterPhoto = true;
    } else {
      console.log(task);
    }
  }

  openImageInNewTab() {
    if (this.photoLinkSrc) {
      window.open(this.photoLinkSrc, "_blank");
    }
  }

  formatDate(date: string | undefined) {
    return date ? format(parseISO(date), "dd-MM-yyyy HH:mm") : "";
  }
}
